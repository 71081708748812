import customAlert from "components/common/Alert/customAlert";

// Service_type
export const Service_GL: boolean =
  process.env.REACT_APP_SERVICE_TYPE === "global";

//// 다국어 공통 키
export const loginTranslationKey: string = "This_service_requires_login";

const data = [
  "vfun",
  "vfun-lounge",
  "billing",
  "vfun-stage",
  "vfun-lounge-stage",
  "billing-qa"
];

export function redirectURL(url: string, target: string, isLauncher?: boolean) {
  // vfun, vfun-lounge, vfun-ground, billing,
  // vfun-stage, vfun-lounge.stage, vfun-ground-stage, billing-qa
  // 포함하면 self, 아님 blank
  const contains = data.some((domain) => url.includes(domain));

  if (isLauncher && !contains) {
    window.app.vFunOpenWebBrowser(url);
  } else {
    window.open(url, target);
  }
}

export function billingURL(url: string, target: string, isLauncher: boolean) {
  if (isLauncher) {
    window.app.vFunOpenWebBrowser(url);
  } else {
    window.open(url, target);
  }
}

//// 날짜 형식
export const dateFormType1 = (date: string): string => {
  const creat = new Date(date);
  let month = String(creat.getMonth() + 1).padStart(2, "0");
  let day = String(creat.getDate()).padStart(2, "0");

  return `${creat.getFullYear()}-${month}-${day}`;
};

export const dateFormType2 = (date: string): string => {
  const creat = new Date(date);
  let month = String(creat.getMonth() + 1).padStart(2, "0");
  let day = String(creat.getDate()).padStart(2, "0");

  return `${creat.getFullYear()}.${month}.${day}`;
};

export const dateFormType3 = (date: string) => {
  const creat = new Date(date);
  let year = creat.getFullYear();
  let day = String(creat.getDate()).padStart(2, "0");

  const month = () => {
    switch (String(creat.getMonth() + 1).padStart(2, "0")) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
    }
  };

  return `${month()} ${day}, ${year}`;
};

// 게시물 상태
export const answerStatus = (status: number) => {
  switch (status) {
    case 0:
      return "on_hold";
    case 1:
      return "answered";
    case 2:
      return "confirm_check";
    case 3:
      return "deferred";
  }
};

// 상태 색
export const statusColor = (status: number) => {
  switch (status) {
    case 0:
      return "color-green";
    case 1:
      return "color-mint";
    case 2:
      return "color-green";
    case 3:
      return "color-r";
  }
};

// 사진 타입
export const allowedTypes = [
  "image/jpg",
  "image/gif",
  "image/png",
  "image/bmp",
  "image/jpeg"
];

export function deletetag(input: string | undefined, allow: string[]) {
  let regExp;

  if (allow.length !== 0)
    regExp = "<\\/?(?!(" + allow.join("|") + "))\\b[^>]*>";
  else regExp = "</?[^>]*>";

  let result = input?.replace(new RegExp(regExp, "gi"), "");

  const replacements = {
    "&nbsp;": " ",
    "&bull;": ":",
    "&#39;": "'",
    "&quot;": " ",
    "&agrave;": "à",
    "&ecirc;": "ê",
    "&iexcl;": "¡",
    "&aacute;": "á",
    "&ccedil;": "ç",
    "&Ccedil;": "Ç",
    "&atilde;": "ã",
    "&Atilde;": "Ã",
    "&Eacute;": "É",
    "&eacute;": "é",
    "&Agrave;": "À",
    "&Aacute;": "Á",
    "&Acirc;": "Â",
    "&Auml;": "Ä",
    "&Aring;": "Å",
    "&egrave;": "è",
    "&Egrave;": "È",
    "&Ecirc;": "Ê",
    "&Euml;": "Ë",
    "&Igrave;": "Ì",
    "&Iacute;": "Í",
    "&Icirc;": "Î",
    "&Iuml;": "Ï",
    "&igrave;": "ì",
    "&iacute;": "í",
    "&icirc;": "î",
    "&iuml;": "ï",
    "&Ntilde;": "Ñ",
    "&ntilde;": "ñ",
    "&Ograve;": "Ò",
    "&Oacute;": "Ó",
    "&Ocirc;": "Ô",
    "&Otilde;": "Õ",
    "&Ouml;": "Ö",
    "&ograve;": "ò",
    "&oacute;": "ó",
    "&ocirc;": "ô",
    "&otilde;": "õ",
    "&ouml;": "ö",
    "&Oslash;": "Ø",
    "&oslash;": "ø",
    "&#140;": "Œ",
    "&#156;": "œ",
    "&#138;": "Š",
    "&#154;": "š",
    "&Ugrave;": "Ù",
    "&Uacute;": "Ú",
    "&Ucirc;": "Û",
    "&Uuml;": "Ü",
    "&ugrave;": "ù",
    "&uacute;": "ú",
    "&ucirc;": "û",
    "&uuml;": "ü",
    "&#181;": "µ",
    "&#215;": "x",
    "&Yacute;": "Ý",
    "&#159;": "Ÿ",
    "&yacute;": "ý",
    "&yuml;": "ÿ",
    "&#176;": "°",
    "&#134;": "†",
    "&#135;": "‡",
    "&lt;": "<",
    "&gt;": ">",
    "&#177;": "±",
    "&#171;": "«",
    "&#187;": "»",
    "&#191;": "¿",
    "&#161;": "¡",
    "&#183;": "·",
    "&#149;": "•",
    "&#153;": "™",
    "&copy;": "©",
    "&reg;": "®",
    "&#167;": "§",
    "&#182;": "¶"
  };

  for (const [search, replace] of Object.entries(replacements)) {
    result = result?.replace(RegExp(search, "gi"), replace);
  }

  return result;
}

// 런처 로그인 체크
export const LauncherLogin = (launcherlogin: boolean) => {
  if (launcherlogin) {
    window.app.vfunRequestLogin();
  }
};

// 오늘로부터 15일 전 나온 메뉴인가 판단
export const compareDate = (release_at: string) => {
  let created = new Date(release_at);
  let today = new Date();

  // 두 날짜 간의 일수 차이 계산
  let delta = Math.floor(
    (today.setHours(0, 0, 0, 0) - created.setHours(0, 0, 0, 0)) /
      (1000 * 60 * 60 * 24)
  );

  if (delta < 0 || delta <= 15) {
    return true;
  } else {
    return false;
  }
};

const strWindowFeatures = () => {
  let openW = 768;
  let openH = 540;
  let Left = document.body.offsetWidth / 2 - openW / 2;
  let Top = document.body.offsetHeight / 2 - (openH + openH / 2);
  let strWindowFeatures = `width=${openW},height=${openH},left=${Left},top=${Top},resizable,scrollbars=yes,status=1`;
  return strWindowFeatures;
};

export function PopupShare(
  type: string,
  url: string,
  title: string,
  isLauncher: boolean
) {
  let strUrl = "";

  switch (type.toLowerCase()) {
    case "facebook":
      strUrl = "//www.facebook.com/sharer/sharer.php?u=" + url;
      break;
    case "twitter":
      strUrl = "//twitter.com/share?url=" + url;
      break;
    case "weibo":
      strUrl = "http://service.weibo.com/share/share.php?url=" + url; // + {URL}&title={TITLE}" + url;
      if (title !== "") {
        strUrl =
          strUrl +
          "&title=" +
          title +
          "&pic=//file.qijisoft.com/Valofe_file/web/meta/meta-fb-lostsaga-cn.jpg";
      }

      break;
    case "qq":
      strUrl =
        "http://connect.qq.com/widget/shareqq/iframe_index.html?url=" + url;
      if (title !== "") {
        strUrl = strUrl + "&title=" + title;
      }
      break;
    default:
      strUrl = window.location.href;
  }
  if (isLauncher) {
    window.app.vFunOpenWebBrowser(strUrl);
  } else {
    window.open(strUrl, type, strWindowFeatures());
  }
}

export const downloadType = (section: number) => {
  if (section === 2) {
    return "aos";
  } else if (section === 3) {
    return "ios";
  }
};

// pc mobile 동일
// isopen 1은 open
// 2 comming soon
// 3 pre oder
// 4 pre 캐릭터 생성

// section
// 1 pc
// 2 google
// 3 appstore

export const sideBarGameTitle = (section: number, isOpen: number) => {
  const isOpenState = () => {
    switch (isOpen) {
      case 2:
        return "Coming Soon";
      case 3:
        // return "Pre-Register";
        return "JOIN";
      case 4:
        // return "Character Pre-Creation";
        return "JOIN";
      default:
        return "";
    }
  };

  const type = () => {
    switch (section) {
      case 1:
        return "Play VFUN";
      case 2:
        return "Google Play";
      case 3:
        return "App Store";
      default:
        return "";
    }
  };

  if (isOpen === 1) {
    return type();
  } else {
    return isOpenState();
  }
};

export const sideBarGameIcon = (section: number) => {
  const icon = () => {
    switch (section) {
      case 1:
        return "icon_VFUN.png";
      case 2:
        return "icon_GooglePlay.png";
      case 3:
        return "icon_AppStore.png";
      default:
        return "";
    }
  };

  return `<img src="https://file.qijisoft.com/Valofe_file/web/vfun/images/icon/${icon()}"/>`;
};

export const goLibrary = (service_code: string) => {
  switch (service_code) {
    case "fwtr":
      return "https://fwtr.valofe.com/";
    case "at_jp":
      return "https://at.pmang.jp/";
    default:
      return `/library?service_code=${service_code}&section=home`;
  }
};

export const outLink = (service_code: string) => {
  if (service_code === "fwtr" || service_code === "at_jp") {
    return "_blank";
  }
};

export const onCopy = async (
  url: string,
  possible: string,
  impossible: string
) => {
  try {
    await navigator.clipboard.writeText(url);
    customAlert(possible);
    return true;
  } catch (error) {
    customAlert(impossible);
    return false;
  }
};

import axios from "axios";
import { BASE_PATH } from "./api";
import { headersType } from "atoms/userInfoAtom";

export type ILauncherData = {
  protocol: string;
  service_code: string;
  user_id: string;
  user_otp: string;
  game_run_url: string;
};

export const launcherStartApi = `${BASE_PATH}/api/auth/LauncherStart`;

export const eventTrackingApi = async (
  service_code: string,
  type: string,
  headers: headersType
) => {
  try {
    const res = await axios.get(
      `${BASE_PATH}/api/common/eventTracking/${service_code}/${type}`,
      {
        headers: headers
      }
    );
  } catch (error) {
    console.log("eventTrackingApi error");
  }
};

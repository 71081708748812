import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MembershipWrap from "components/common/Wrap/MembershipWrap";
import InputWrap from "components/MembershipComponent/InputWrap";
import { t } from "i18next";
import parse from "html-react-parser";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  inputValueState,
  isValidState,
  validTextState
} from "atoms/userInfoAtom";
import { dateFormType1, redirectURL } from "utils/CommonFunc";
import axios from "axios";
import { nickNameApi, snsSigninProcApi } from "api/authApi";
import { useCookies } from "react-cookie";
import { wrapLoadingState } from "atoms/uiAtom";
import { useSigninSetCookie } from "hooks/useSigninSetCookie";
import customAlert from "components/common/Alert/customAlert";

function SnsSignUp() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useRecoilState(inputValueState);
  const [validText, setValidText] = useRecoilState(validTextState);
  const [isValid, setIsValid] = useRecoilState(isValidState);
  const [nameFocus, setIsNameFocus] = useState(false);
  const [nameVolid, setNameVolid] = useState(false);
  const [birthVolid, setBirthVolid] = useState(false);
  const [receiveLetter, setReceiveLetter] = useState(false);
  const [cookies, setCookie] = useCookies();
  const setIsLoading = useSetRecoilState(wrapLoadingState);
  const [nickNameCheck, setNickNameCheck] = useState(false);
  const signinSetCookie = useSigninSetCookie();
  const stateArr = decodeURIComponent(
    window.location.href.split("state=")[1]
  ).split(",");

  const type = stateArr[0].split("=")[1];
  const birthday = stateArr[1].split("=")[1];
  const email = stateArr[2].split("=")[1];
  const first_name = stateArr[3].split("=")[1];
  const last_name = stateArr[4].split("=")[1];
  const id = stateArr[5].split("=")[1];

  const alertMove = async (message: string) => {
    const result = await customAlert(t(message));

    if (result) {
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    if (
      type !== null &&
      birthday !== null &&
      email !== null &&
      first_name !== null &&
      last_name !== null &&
      id !== null
    ) {
      if (first_name !== "" && last_name !== "") {
        setNameVolid(true);
      }
      if (birthday !== "") {
        setBirthVolid(true);
      }
    } else {
      alertMove("my_inquiry_desc13");
      return;
    }
  }, []);

  useEffect(() => {
    if (nameVolid && birthVolid) {
      setIsValid({ ...isValid, name: true, birth: true });
    } else if (nameVolid && !birthVolid) {
      setIsValid({ ...isValid, name: true, birth: false });
    } else if (!nameVolid && birthVolid) {
      setIsValid({ ...isValid, name: false, birth: true });
    } else {
      setIsValid({ ...isValid, name: false, birth: false });
    }
  }, [nameVolid, birthVolid]);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  // 이름
  useEffect(() => {
    const nameExp = /^[a-zA-Z]*$/;

    if (first_name === "" && last_name === "") {
      if (
        nameExp.test(inputValue.firstName) &&
        nameExp.test(inputValue.lastName)
      ) {
        setValidText({ ...validText, name: "" });
        setIsValid({ ...isValid, name: true });
      } else {
        setValidText({
          ...validText,
          name: `${t("sns_signup_alert_only_alphabet")}`
        });
        setIsValid({ ...isValid, name: false });
      }
    }
  }, [inputValue.firstName, inputValue.lastName]);

  const nameOnBlur = () => {
    let contLen =
      inputValue.firstName.length === 0 || inputValue.lastName.length === 0;
    if (contLen) {
      setValidText({
        ...validText,
        name: `${t("login_user_name_missing")}`
      });
    }
    setIsNameFocus(false);
  };

  // 생년월일
  useEffect(() => {
    // const birthExp = /^[0-9]{8}$/;
    const birthExp = /^[\d-]{10}$/;
    if (!birthExp.test(inputValue.birth)) {
      setIsValid({ ...isValid, birth: false });
    } else {
      const today = new Date();
      const birth = new Date(Date.parse(inputValue.birth));
      const limitDate: Date = new Date(
        today.getFullYear() - 14,
        today.getMonth(),
        today.getDate()
      );

      if (Number.isNaN(birth.getTime())) {
        setIsValid({ ...isValid, birth: false });
      } else {
        if (birth > limitDate) {
          setIsValid({ ...isValid, birth: false });
          setValidText({
            ...validText,
            birth: `${parse(t("gdpr_birth"))}`
          });
          setInputValue({ ...inputValue, birth: "" });
        } else {
          setIsValid({ ...isValid, birth: true });
          setValidText({ ...validText, birth: "" });
        }
      }
    }
  }, [inputValue.birth]);

  const birthOnBlur = () => {
    if (!isValid.birth) {
      if (inputValue.birth.length === 0) {
        setValidText({
          ...validText,
          birth: `${t("signup_birth_missing")}`
        });
      } else {
        setValidText({
          ...validText,
          birth: `${t("signup_birth_error")}`
        });
      }
    } else {
      setValidText({ ...validText, birth: `` });
    }
  };

  // 생년월일에 하이픈 기호 넣기
  useEffect(() => {
    const value = inputValue.birth.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;
        case 8:
          result += "-";
          break;

        default:
          break;
      }

      result += value[i];
    }

    setInputValue({ ...inputValue, birth: result });
  }, [inputValue.birth]);

  // 닉네임 유효성
  useEffect(() => {
    const nickNameExp = /^[0-9A-Za-z][0-9A-Za-z_]{3,12}$/i;
    if (nickNameExp.test(inputValue.nickName)) {
      setNickNameCheck(true);
    } else {
      setNickNameCheck(false);
    }
  }, [inputValue.nickName]);

  const nickNameOnBlur = async () => {
    if (nickNameCheck) {
      const fetchApi = async () => {
        try {
          let res = await axios.get(nickNameApi(inputValue.nickName));
          if (res.data.result === 1) {
            setIsValid({ ...isValid, id: true });
          } else {
            setValidText({
              ...validText,
              id: `${t("already_registered_nickname")}`
            });
            setIsValid({ ...isValid, id: false });
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchApi();
    } else if (inputValue.nickName.length === 0) {
      setValidText({ ...validText, id: `${t("please_enter_a_nickname")}` });
    } else {
      setValidText({
        ...validText,
        id: `${t("alert_input_id_chr_rule")}`
      });
    }
  };

  useEffect(() => {
    if (isValid.id && isValid.name && isValid.birth) {
      setIsValid({ ...isValid, isNext: true });
    } else {
      setIsValid({ ...isValid, isNext: false });
    }
  }, [isValid.id, isValid.name, isValid.birth]);

  const signUpCheck = async () => {
    if (isValid.isNext) {
      setIsLoading(true);
      try {
        let res = await axios.post(snsSigninProcApi, {
          user_f_name: first_name !== "" ? first_name : inputValue.firstName,
          user_l_name: last_name !== "" ? last_name : inputValue.lastName,
          user_birth:
            birthday !== "" ? dateFormType1(birthday) : inputValue.birth,
          user_email: email,
          receive_letter: receiveLetter ? "Y" : "N",
          nickname: inputValue.nickName,
          user_id: id,
          sns_type: type,
          site_code: cookies["service_code"] || "vfun"
        });
        const result = res.data.result;
        if (result === 1) {
          signinSetCookie(res.data.data);
          setIsLoading(false);

          const isAlert = await customAlert(t("signup_complete_desc1"));
          if (isAlert) {
            redirectURL("/", "_self", false);
          }
        } else if (result === -88) {
          setIsLoading(false);
          customAlert("차단된 아이피입니다.");
        } else if (result === -5) {
          setIsLoading(false);
          customAlert("vfun_error_leave_user");
        } else {
          setIsLoading(false);
          customAlert(t("my_inquiry_desc13"));
        }
      } catch (error) {
        console.log("sns", error);
      }
    }
  };

  const onClickSignUp = () => {
    signUpCheck();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (isValid.isNext) {
      if (e.key === "Enter") {
        e.preventDefault();
        signUpCheck();
      }
    }
  };

  return (
    <MembershipWrap title="signup" mb="mb45">
      <div className="cont on" id="cont_1">
        <p className="txt-info">{t("sns_signup_step1_desc")}</p>
        <form onKeyDown={onKeyDown}>
          {/* 이름 */}
          <InputWrap
            htmlFor="firstname, lastname"
            className={`border-type  ${nameFocus ? "on" : ""} clearfix`}
          >
            <input
              type="text"
              className="border-none-type width60"
              placeholder={t("f_name")}
              name="firstName"
              value={first_name !== "" ? first_name : inputValue.firstName}
              onChange={onValueChange}
              onBlur={nameOnBlur}
              readOnly={first_name !== "" ? true : false}
              onFocus={() => setIsNameFocus(true)}
            />
            <input
              type="text"
              className="border-none-type width40"
              placeholder={t("l_name")}
              name="lastName"
              value={last_name !== "" ? last_name : inputValue.lastName}
              onChange={onValueChange}
              onBlur={nameOnBlur}
              readOnly={last_name !== "" ? true : false}
              onFocus={() => setIsNameFocus(true)}
            />
            <span className="lastname-bar" />
          </InputWrap>
          <span className="alert round">{validText.name}</span>

          {/* 이메일 */}
          <InputWrap htmlFor="email">
            <input
              type="email"
              className=""
              placeholder={t("email")}
              name="email"
              value={email}
              readOnly={true}
            />
            <span className={`alert round on green`}>
              {t("sns_signup_alert_auth_email")}
            </span>
          </InputWrap>

          {/* 생일 */}
          <InputWrap htmlFor="birth">
            <input
              type="date"
              aria-label="Date-Time"
              placeholder={t("birth_day")}
              maxLength={10}
              name="birth"
              value={
                birthday !== "" ? dateFormType1(birthday) : inputValue.birth
              }
              onChange={onValueChange}
              onBlur={birthOnBlur}
              readOnly={birthday !== "" ? true : false}
            />
            <span className="alert round on">{validText.birth}</span>
          </InputWrap>

          {/* 닉네임 */}
          <InputWrap htmlFor="nickname">
            <input
              type="text"
              name="nickName"
              className=""
              onChange={onValueChange}
              onBlur={nickNameOnBlur}
              value={inputValue.nickName}
              placeholder="Nick Name"
              maxLength={10}
            />
            <span className="alert round">{validText.id}</span>
          </InputWrap>

          {/* 서비스 메일 구독 */}
          <InputWrap
            className="mt20 clearfix label-chk-sns"
            htmlFor="receive_letter"
          >
            <input
              type="checkbox"
              name="receive_letter"
              className="square-chk"
              onChange={() => setReceiveLetter((prev) => !prev)}
              checked={receiveLetter}
            />
            {t("receive_newsletter")}
          </InputWrap>

          <div className="wrap-btn mt50 mb60">
            <Link
              to={window.location.href}
              className={`btn _2 round-type ${
                isValid.isNext ? "valid-type-pass" : "valid-type-not"
              } on`}
              onClick={onClickSignUp}
            >
              {t("confirm")}
            </Link>
          </div>
        </form>
      </div>
    </MembershipWrap>
  );
}

export default SnsSignUp;

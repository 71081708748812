import axios from "axios";
import { BASE_PATH } from "./api";
import { headersType } from "atoms/userInfoAtom";

// 라이브러리 게임 정보
export interface Idownload {
  download_url: string;
  section: number;
  is_open: number;
}

export interface Isns {
  sns_type: string;
  sns_url: string;
  sns_qrcode: string;
}

export interface GameInfoData {
  default_language: string;
  desc: string | null;
  logo_url: string;
  banner_url: string;
  release_at: string;
  title: string | null;
  thumbnail_url: string;
  pc_banner_url: string;
  vfun_lounge_info: boolean;
  vfun_ground_flag: "Y" | "N";
  vfun_landing_info: {
    status: boolean;
    title: string | null;
  };
  web_url: string;
  game_type: string;
  download: Idownload[];
  game_genre: string;
  sns: Isns[] | [] | null;
  language: string;
  launcher_support_info: {
    redirect_url: string | null;
    support: boolean;
  };
}

export interface IGameInfo {
  data: GameInfoData;
  result: number;
  user_like_status: number;
}

export const getGameInfo = (
  service_code: string,
  langCode: string,
  headers: headersType
) =>
  axios
    .get(`${BASE_PATH}/api/library/gameinfo/${service_code}?lang=${langCode}`, {
      headers: headers
    })
    .then((res) => res.data);

// 게임 좋아요
export const getGameLikeApi = (service_code: string) =>
  `${BASE_PATH}/api/vfunlounge/${service_code}/like`;

// sections
export interface GameSectionData {
  idx: number;
  lang_code: string;
  link_type: string;
  link_url: string | null;
}

export interface IGameSections {
  data: GameSectionData[];
  result: number;
}

export const getGameSections = (service_code: string) =>
  axios
    .get(`${BASE_PATH}/api/library/sections/${service_code}`)
    .then((res) => res.data);

// detail

interface DetailData {
  content: string;
  created_at: string;
  idx: number;
  lang_code: string;
  like_count: number;
  title: string;
  user_like_status: number;
  section_code: string;
  service_code: string;
  game_title: string;
}

export interface IDetail {
  data: DetailData;
  result: number;
}

export const getDetail = (
  idx: number,
  headers: headersType,
  langCode: string
) =>
  axios
    .get(`${BASE_PATH}/api/library/lounge_post/${idx}?lang=${langCode}`, {
      headers: headers
    })
    .then((res) => res.data);

// library like
export const detailLike = (service_code: string, board_idx: string) =>
  `${BASE_PATH}/api/vfunlounge/${service_code}/like/${board_idx}`;

// 모바일 라이브러리
interface IFeaturesData {
  contents_url: string | null;
  image_url: string;
  is_video: string;
}

export interface IFeatures {
  data: IFeaturesData[];
  result: number;
}

export const getFeatures = (service_code: string, langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/library/features/${service_code}?lang=${langCode}`)
    .then((res) => res.data);

export interface IWorldView {
  data: string;
  result: number;
}

export const getWorldViewApi = (service_code: string, langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/library/world_view/${service_code}?lang=${langCode}`)
    .then((res) => res.data);

interface ISystemData {
  description: string;
  sub_title: string;
  title: string;
}

export interface ISystem {
  data: ISystemData[];
  result: number;
}

export const getSystemApi = (service_code: string, langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/library/system/${service_code}?lang=${langCode}`)
    .then((res) => res.data);

export interface ICoupon {
  result: number;
  // data: [{ coupon_code: string }];
  data: {
    coupon_list: [
      {
        coupon_code: string;
      }
    ];
    coupon_out_link: string;
    game_server_list: [
      {
        server_id: number;
        server_name: string;
      }
    ];
  };
}

export const getCouponApi = (service_code: string) =>
  axios
    .get(`${BASE_PATH}/api/library/coupon_info/${service_code}`)
    .then((res) => res.data);

// mobile list

export interface IPostData {
  content: string;
  created_at: string;
  idx: number;
  lang_code: string;
  logo_url: string;
  pc_banner_url: string;
  title: string;
  images: string;
}

export const getMobileList = (
  service_code: string,
  section: string,
  langCode: string,
  pageParam: number
) =>
  `${BASE_PATH}/api/vfunlounge/posts/${service_code}/${section}?lang=${langCode}&page=${pageParam}`;

export interface MobileDetailData {
  admin_flag: number;
  admin_icon: string;
  idx: number;
  service_code: string;
  section: number;
  language: string;
  title: string;
  nickname: string;
  content: string;
  images: null | string[];
  status: number;
  user_id: string;
  like_count: number;
  created_at: string;
  updated_at: string;
  user_ip: string;
  comments_count: number;
  user_like_status: number;
}

export interface IMobileDetail {
  result: number;
  msg: string;
  data: MobileDetailData;
}

export const getMobileDetail = (
  service_code: string,
  title: string,
  idx: string,
  headers: headersType
) =>
  axios
    .get(`${BASE_PATH}/api/vfunlounge/posts/${service_code}/${title}/${idx}`, {
      headers: headers
    })
    .then((res) => res.data);

interface IBoardData {
  created_at: string;
  idx: number;

  service_code: string;

  title: string;

  user_id: string;
}

export interface IBoard {
  data: {
    data: IBoardData[];
  };
  result: number;
}

export const getBoardNotice = (service_code: string, langCode: string) =>
  axios
    .get(
      `${BASE_PATH}/api/vfunlounge/posts/${service_code}/Notice?lang=${langCode}&page=1 `
    )
    .then((res) => res.data);

export const getBoardEvent = (service_code: string, langCode: string) =>
  axios
    .get(
      `${BASE_PATH}/api/vfunlounge/posts/${service_code}/Event?lang=${langCode}&page=1 `
    )
    .then((res) => res.data);

// 리뉴얼 api
// Home

interface INormalData {
  created_at: string;
  idx: number;
  images: string[];
  section_lang_code: string;
  section_title: string;
  title: string;
}

export interface IHome {
  cur: string;
  result: number;
  data: {
    pre_event: string;
    pre_event_info: {
      is_milestone: string;
      end_date: string;
      is_character_creation: string;
      milestone_step_count: number;
      milestone_step_info: number[];
      reservation_count: number;
      start_date: string;
      guide_lounge_idx: number;
      is_sms_auth: string;
      desc: string;
      count_down_info: {
        d: number;
        h: number;
        i: number;
        s: number;
      };
      invite_count: number;
      invite_reward_post_idx: number;
    };
    normal?: {
      top_posts: INormalData[];
    };
  };
}

export const getHomeApi = (service_code: string, lang: string) =>
  axios
    .get(`${BASE_PATH}/api/library/home/${service_code}?lang=${lang}`)
    .then((res) => res.data);

interface ICommunityListData {
  content: string;
  created_at: string;
  idx: number;
  images: null;
  thumnail: string;
  service_code: string;
  title: string;
  section_lang_code?: string;
}

export interface ICommunityList {
  data: {
    event: ICommunityListData[];
  };
  result: number;
}

export const communityListApi = (service_code: string) =>
  axios
    .get(`${BASE_PATH}/api/library/home/community/${service_code}`)
    .then((res) => res.data);

// 메인 api
interface IMainListData {
  created_at: string;
  idx: number;
  images: string;
  like_count: number;
  section_lang_code: string;
  title: string;
  content?: string;
}

export interface IPostList {
  data: {
    posts: IMainListData[];
  };
  result: number;
}

export const libraryPostsApi = (
  service_code: string,
  langCode: string,
  sections: string,
  limit: number
) =>
  axios
    .get(
      `${BASE_PATH}/api/library/home/posts/news/${service_code}?lang=${langCode}&sections=${sections}&limit=${limit}`
    )
    .then((res) => res.data);

export const getAirbridgeSendEvent = (
  service_code: string,
  langCode: string,
  client_id: string,
  user_agent: string,
  short_id: string,
  channel: string,
  category: string,
  headers: headersType
) =>
  axios
    .get(
      `${BASE_PATH}/api/airbridge/send_event?service_code=${service_code}&user_lang=${langCode}&client_id=${client_id}&user_agent=${user_agent}&short_id=${short_id}&channel=${channel}&category=${category}`,
      { headers: headers }
    )
    .then((res) => console.log("airbridge", res));

// 랭킹
export interface IMyRanking {
  msg?: string;
  result: number;
  data?: {
    my_ranking: {
      point: number | string;
      ranking: number | string;
      user_id: string;
    };
  };
}

export const myRankingApi = (
  service_code: string,
  headers: headersType,
  type: number
) =>
  axios
    .get(
      `${BASE_PATH}/api/library/home/ranking/type/my/${service_code}?type=${type}`,
      {
        headers: headers
      }
    )
    .then((res) => res.data);

interface IType {
  code: number;
  name: string;
}

export interface IRankingType {
  data: {
    ranking_type: IType[];
  };
  result: number;
}

export const rankingTypeApi = (service_code: string, lang: string) =>
  axios
    .get(
      `${BASE_PATH}/api/library/home/ranking/type/${service_code}?lang=${lang}`
    )
    .then((res) => res.data);

interface IRankingListData {
  nick: string;
  point: number;
  rank: number;
  up_down: number;
  rank_title: number;
}

export interface IRnakingList {
  data: {
    last_update_time: string;
    ranking: IRankingListData[];
  };
  result: number;
}

export const rankingListApi = (service_code: string, type: number) =>
  axios
    .get(`${BASE_PATH}/api/library/home/ranking/${service_code}?type=${type}`)
    .then((res) => res.data);

// season ranking
export interface IMySeRanking {
  result: number;
  data?: {
    my_ranking_info: {
      Season: number;
      ID: string;
      RankType: string;
      Rank: string;
      PersonName: string;
      RankParam: string;
      GuildName: string | null;
      my_space: string | null;
    };
  };
}

export const mySeRankingApi = (
  service_code: string,
  headers: headersType,
  season: number,
  rank_type: number
) =>
  axios
    .get(
      `${BASE_PATH}/api/library/home/ranking/season/my_ranking?service_code=${service_code}&season=${season}&rank_type=${rank_type}`,
      {
        headers: headers
      }
    )
    .then((res) => res.data);

export interface IRankingSeason {
  result: number;
  data: {
    max_season: number;
  };
}
export const rankingSeasonApi = (service_code: string) =>
  axios
    .get(
      `${BASE_PATH}/api/library/home/ranking/season?service_code=${service_code}`
    )
    .then((res) => res.data);

interface ILeagueData {
  Season: number;
  RankType: number;
  RankTitle: string;
}
export interface IRankingLeague {
  result: number;
  data: {
    season_league_list: ILeagueData[];
  };
}
export const rankingLeagueApi = (service_code: string, season: number) =>
  axios
    .get(
      `${BASE_PATH}/api/library/home/ranking/season/league?service_code=${service_code}&season=${season}`
    )
    .then((res) => res.data);

interface ISeRankingListData {
  Season: string;
  AccountID: string;
  RankType: string;
  Rank: string;
  PersonName: string;
  RankParam: string;
  GuildName: string;
  my_space: string;
  Country: string;
  Nickname: string;
  Region: string;
  Server: string;
}

export interface ISeRankingList {
  data: {
    last_update: string;
    ranking_list: ISeRankingListData[];
    total_page: number;
  };
  result: number;
}

export const seRankingListApi = (
  service_code: string,
  season: number,
  type: number,
  page: number
) =>
  axios
    .get(
      `${BASE_PATH}/api/library/home/ranking/season/list?service_code=${service_code}&page=${page}&season=${season}&rank_type=${type}`
    )
    .then((res) => res.data);

// smsPopUP
export const sendSmsApi = `${BASE_PATH}/api/library/reservation/send_sms`;

export const reservationVerifyApi = `${BASE_PATH}/api/library/reservation/verify`;

export const inviteCodeApi = (service_code: string) =>
  `${BASE_PATH}/api/library/reservation/pre_regist/${service_code}`;

// default pre register
export const defaultPreRegisterApi = (
  service_code: string,
  headers: headersType
) =>
  axios.post(
    `${BASE_PATH}/api/library/reservation/pre_regist/${service_code}`,
    {},
    {
      headers: headers
    }
  );

// landing popup
interface Iposts_info {
  thumbnail_url: string;
  title: string;
  idx: number;
}

export interface ILandingPopUp {
  data: {
    landing_info: {
      idx: number;
      landing_posts_info: Iposts_info[];
      landing_title: string;
      logo_url: string;
      main_image_url: string;
      service_code: string;
    };
  };
  result: number;
}

export const landingPopUpApi = (service_code: string, langCode: string) =>
  axios
    .get(
      `${BASE_PATH}/api/library/landing_info/${service_code}?lang=${langCode}`
    )
    .then((res) => res.data);

export interface IClassListData {
  idx: number;
  parent_idx: number;
  title: string;
  thumbnail_url: string;
}
export interface IClassList {
  result: number;
  data: IClassListData[];
}
export const classListApi = (service_code: string, langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/library/class/${service_code}?lang=${langCode}`)
    .then((res) => res.data);

export interface IClassInfo {
  result: number;
  data: {
    title: string;
    contents: string;
    tag: string;
    thumbnail_url: string;
    video_url: string;
    lounge_post_idx: number;
  };
}
export const classInfoApi = (
  service_code: string,
  classIdx: number,
  langCode: string
) =>
  axios
    .get(
      `${BASE_PATH}/api/library/class/${service_code}/${classIdx}?lang=${langCode}`
    )
    .then((res) => res.data);

import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { wrapLoadingState } from "atoms/uiAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "axios";
import {
  ILauncherSnsLogin,
  SnsSignupCheckApi,
  launcherSignInProcApi
} from "api/authApi";
import { isLauncherState } from "atoms/userInfoAtom";
import { redirectURL } from "utils/CommonFunc";

function LauncherFacebooklogin() {
  const [cookies] = useCookies();
  const userInfo: ILauncherSnsLogin = cookies["sns_info"];
  const isLauncher = useRecoilValue(isLauncherState);
  const setIsLoading = useSetRecoilState(wrapLoadingState);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo !== undefined || userInfo !== null) {
      // vfun 아이디가 비어있으면 신규 회원, 있으면 기존 회원
      const signUpCheck = async () => {
        setIsLoading(true);
        try {
          let res = await axios.get(
            SnsSignupCheckApi("Facebook", userInfo.real_id)
          );
          if (res.data.result === 1) {
            setIsLoading(false);

            const state = `type=Facebook,birthday=${
              userInfo.birthday || ""
            },email=${userInfo.email},first_name=${
              userInfo.first_name || ""
            },last_name=${userInfo.last_name || ""},id=${userInfo.real_id}`;

            navigate(
              `/membership/sns_login?state=${encodeURIComponent(state)}`
            );
            // 이미 아이디가 있을 경우
          } else if (res.data.result === -98) {
            try {
              let res = await axios.post(launcherSignInProcApi, {
                user_id: userInfo.vfun_id
              });

              if (res.data.result === 1) {
                redirectURL(
                  res.data.launcher_redirect_url,
                  "_self",
                  isLauncher
                );
              }
            } catch (error) {
              console.log("facebook l", error);
            }
          }
        } catch (error) {
          console.log("fb l signUp", error);
        }
        setIsLoading(false);
      };

      signUpCheck();
    }
  }, [userInfo]);

  return null;
}

export default LauncherFacebooklogin;
